import _ from 'lodash'
import { PlatformEnvData, PlatformUtils, WixCodeApi, FeatureName, PlatformLogger } from '@wix/thunderbolt-symbols'
import { WixCodeViewerAppUtils } from './wixCodeViewerAppUtils'
import { BootstrapData } from '../types'
import { ModelsAPI } from './types'
import { wixCodeSdkFactories } from '../wixCodeSdks'

export type WixCodeApiFactory = {
	initWixCodeApiForApplication: (appDefinitionId: string) => Promise<WixCodeApi>
}

export function createWixCodeApiFactory({
	bootstrapData,
	wixCodeViewerAppUtils,
	modelsApi,
	platformUtils,
	createSdkHandlers,
	platformEnvData,
	logger
}: {
	bootstrapData: BootstrapData
	wixCodeViewerAppUtils: WixCodeViewerAppUtils
	modelsApi: ModelsAPI
	platformUtils: PlatformUtils
	createSdkHandlers: (pageId: string) => any
	platformEnvData: PlatformEnvData
	logger: PlatformLogger
}): WixCodeApiFactory {
	type SdkFactory = (appDefinitionId: string) => { [namespace: string]: any }
	const internalNamespaces = {
		// TODO: move this somewhere else
		events: {
			setStaticEventHandlers: wixCodeViewerAppUtils.setStaticEventHandlers
		}
	}

	const depricatedWixCodeApiFactory = [
		'siteMembersWixCodeSdk',
		'seoWixCodeSdk',
		'paymentsWixCodeSdk',
		'paidPlansWixCodeSdk',
		'wixEventsWixCodeSdk',
		'searchWixCodeSdk',
		'bookingsWixCodeSdk',
		'fedopsWixCodeSdk',
		'storesWixCodeSdk',
		'realtimeWixCodeSdk',
		'crmWixCodeSdk',
		'animationsWixCodeSdk',
		'privateWixCodeSdk',
		'dashboardWixCodeSdk'
	]

	const createWixCodeApiFactories = () =>
		Promise.all(
			_.map(wixCodeSdkFactories, async (loader, name: FeatureName) => {
				const featurePageConfig = modelsApi.getFeaturePageConfig(name)
				const featureSiteConfig = bootstrapData.sdkFactoriesSiteFeatureConfigs[name] || {}

				const featureSdkData = bootstrapData.wixCodeSdkProviderData[name] || {} // TODO: remove this once migration is done
				const featureData = { ...featurePageConfig, ...featureSdkData, ...featureSiteConfig } // Will eventually include only page and site config

				const sdkFactory = await loader()

				if (depricatedWixCodeApiFactory.includes(name)) {
					return () => sdkFactory(featureData, createSdkHandlers(bootstrapData.currentPageId), platformUtils, platformEnvData)
				}

				return (appDefinitionId: string) =>
					sdkFactory({
						featureConfig: { ...featureSiteConfig, ...featurePageConfig },
						handlers: createSdkHandlers(bootstrapData.currentPageId),
						platformUtils,
						platformEnvData,
						appDefinitionId
					})
			})
		)

	// @ts-ignore
	const wixCodeSdksPromise: Promise<Array<SdkFactory>> = logger.runAsyncAndReport('createWixCodeApi', createWixCodeApiFactories)

	return {
		initWixCodeApiForApplication: async (appDefinitionId: string) => {
			const factories = await wixCodeSdksPromise
			const wixCodeSdkArray = await Promise.all(_.map(factories, (factory) => factory(appDefinitionId))) // members API (users) returns a promise.
			return Object.assign({}, internalNamespaces, ...wixCodeSdkArray)
		}
	}
}
