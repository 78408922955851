import { createPromise } from '@wix/thunderbolt-commons'
import { PlatformLogger, PublicAPI } from '@wix/thunderbolt-symbols'
import _ from 'lodash'
import { ModelsAPI } from './types'
import { ClientSpecMapAPI } from './clientSpecMapService'
import { BootstrapData } from '../types'

type PublicApiProviderFunc = (appDefinitionId: string) => void
export type AppsPublicApiManager = {
	setPublicApi: (appDefinitionId: string, appModule: PublicAPI) => void
	getPublicApi: (appDefinitionId: string) => Promise<PublicAPI>
	registerPublicApiProvider: (publicApiProviderFunc: PublicApiProviderFunc) => void
}
type PublicApisPromises = { [appDefinitionId: string]: { setPublicApi: (api: PublicAPI) => void; publicApiPromise: Promise<PublicAPI> } }

const createPromiseForApp = () => {
	const { resolver, promise } = createPromise()
	return { publicApiPromise: promise, setPublicApi: resolver }
}

export function AppsPublicApiManagerFactory({
	modelsApi,
	clientSpecMapApi,
	logger,
	handlers,
	bootstrapData,
	importScripts
}: {
	modelsApi: ModelsAPI
	clientSpecMapApi: ClientSpecMapAPI
	logger: PlatformLogger
	handlers: any
	bootstrapData: BootstrapData
	importScripts: (url: string, name: string) => void
}): AppsPublicApiManager {
	const publicApisPromises: PublicApisPromises = _.mapValues(modelsApi.getApplications(), createPromiseForApp)
	let publicApiProviderFunc: PublicApiProviderFunc
	const moduleRepoUrl = bootstrapData.siteAssetsClientInitParams.clientTopology.moduleRepoUrl
	const pageId = bootstrapData.currentPageId

	async function getAllPublicApisOnPage() {
		const appPublicApisArray = _.map(publicApisPromises, (appPublicApi, appDefinitionId) => appPublicApi.publicApiPromise.then((publicApi) => ({ [appDefinitionId]: publicApi })))
		const appPublicApis = await Promise.all(appPublicApisArray)
		return Object.assign({}, ...appPublicApis)
	}

	if (process.env.browser) {
		getAllPublicApisOnPage().then((publicApis) => {
			handlers.registerPublicApiGetter(async () => {
				if (!self.pmrpc) {
					await importScripts(`${moduleRepoUrl}/pm-rpc@2.0.0/build/pm-rpc.min.js`, 'pm-rpc')
				}

				// TODO if we see that IFrames request APIs of apps on site that are not on page,
				// we will appExportsProvider.getAppExports() all apps on site, and deprecate the publicAPIs parameter.
				return _.map(publicApis, (publicAPI, appDefinitionId) => {
					const name = `viewer_platform_public_api_${appDefinitionId}_${pageId}`
					self.pmrpc.api.set(name, publicAPI)
					return name
				})
			})
		})
	}

	return {
		setPublicApi(appDefinitionId: string, publicApi: PublicAPI) {
			publicApisPromises[appDefinitionId].setPublicApi(publicApi)
		},
		async getPublicApi(appDefinitionId: string) {
			if (!clientSpecMapApi.isAppOnSite(appDefinitionId)) {
				throw new Error(`getPublicAPI() of ${appDefinitionId} failed. The app does not exist on site.`)
			}
			if (!publicApisPromises[appDefinitionId]) {
				publicApisPromises[appDefinitionId] = createPromiseForApp()
				if (!publicApiProviderFunc) {
					logger.captureError(new Error('appsPublicApiManager Error: runApplicationFunc is not a function'), {
						tags: { appsPublicApiManager: true },
						extra: { appDefinitionId }
					})
					throw new Error(`getPublicAPI() of ${appDefinitionId} failed`)
				}
				publicApiProviderFunc(appDefinitionId) // the provider resolves the publicApisPromises[appDefinitionId] promise
			}
			return publicApisPromises[appDefinitionId].publicApiPromise
		},
		registerPublicApiProvider(_publicApiProviderFunc: PublicApiProviderFunc) {
			publicApiProviderFunc = _publicApiProviderFunc
		}
	}
}
